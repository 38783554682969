<template>
  <div
    :class="[
      'vf-song-list-container',
      { 'vf-song-list-container-active': checked }
    ]"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
    @click="onSongListClick"
  >
    <!-- 封面 -->
    <div class="cover_cont">
      <el-image class="cover" :fit="'cover'" :src="data.logo" />
      <div class="play_cont" v-show="isShowPlay" @click.stop="handlePlayAll" :style="playActive ? 'position: absolute;right:30px' : 'right: 12px'">
        <img src="../assets/image/all_playicon.png" alt="">
        <span>播放全部</span>
      </div>
    </div>
    <!-- 标题 -->
    <p class="text-over-e" :class="['name', { 'name-active': checked }]">{{ data.name }}</p>
    <!-- 标签 -->
    <p class="tag">
      <Tag v-for="(tag, tagIndex) in data.label" :key="tagIndex" mode="text" plain>{{
          `${tag.name}&nbsp;`
      }} </Tag>
    </p>
  </div>
</template>

<script>
import Tag from './Tag'
export default {
  name: 'SongList',
  props: {
    // 数据
    data: {
      type: Object,
      default: () => {}
    },
    // 行下标
    rowIndex: {
      type: Number,
      default: 0
    },
    hoverIndex: {
      type: Number,
      default: 0
    },
    // 是否选中
    checked: {
      type: Boolean,
      default: false
    },
    isShowPlay: {
      type: Boolean,
      default: false
    },
    // 是否调整全部播放按钮的位置
    playActive: {
      type: Boolean,
      // default: true
    }
  },
  components: { Tag },
  methods: {
    /**
     * 点击歌单
     */
    onSongListClick() {
      // console.log(this.rowIndex)
      this.$emit('onClick', {
        rowIndex: this.rowIndex,
        id: this.data.playlist_id
      })
    },
    /**
     * 播放全部歌单
     */
    handlePlayAll(){
      this.$emit('onPlay')
    },
    // 鼠标滑入显示全部播放按钮
    handleMouseover() {
      // console.log(this.hoverIndex)
      this.$emit('onMouseover', {
        hoverIndex: this.hoverIndex,
        id: this.data.playlist_id
      })
    },
    // 滑出隐藏
    handleMouseleave(){
      this.$emit('onMouseleave')
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-song-list-container {
  @include flex-direction(column);
  width: 220px;
  cursor: pointer;
  &-active {
    position: relative;
    //&:before{
    //  width: 40px;
    //  height: 40px;
    //  content: '';
    //  display: block;
    //  background-size: cover;
    //  position: absolute;
    //  right: 23px;
    //  top: 196px;
    //  background-image: url('~@/assets/image/playlist-checked-icon.png');
    //}
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba($base-color, 0.7);
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 13px 13px 10px 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    &.vf-song-list-container {
      .cover {
        box-shadow: none;
      }
    }
  }
  .cover_cont{
    position: relative;
    .cover {
      @include square(220px);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      object-fit: cover;
    }
    .play_cont{
      position: absolute;
      right: 12px;
      bottom: 12px;
      width: 104px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 20px;
      }
      span{
        font-size: 14px;
        font-family: OPPOSans;
        font-weight: 500;
        color: #333333;
        display: inline-block;
        padding-left: 6px;
      }
    }
  }
  .name {
    @include font-bold($size: 18px);
    line-height: 25px;
    margin-top: 20px;
  }
  .name-active {
    color: $theme-color;
  }
  .tag {
    margin-top: 8px;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /deep/ .vf-tag-text {
      @include font-normal($color: rgba(77, 77, 79, 0.6));
    }
  }
}
</style>
