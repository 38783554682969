<template>
  <!-- 歌单列表 -->
  <div class="app-container">
    <div class="main">
      <PlaylistItem
        v-for="i in playlists"
        :data="i"
        :key="i.playlist_id"
        :select-playlist-id.sync="selectPlaylistId"
      />
      <Pagination
        v-if="total > 0"
        :position="'center'"
        :limit.sync="listQuery.limit"
        :page.sync="listQuery.page"
        @pagination="getPlaylists"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import { fetchPlaylistList } from '@/api/music'
import PlaylistItem from "@/views/playlist/components/PlaylistItem";
import Pagination from '@/components/Pagination'

export default {
  name: 'PlayList',
  components: { PlaylistItem, Pagination },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20
      },
      total: 0,
      selectPlaylistId: '',
      playlists: []
    }
  },
  created() {
    this.getPlaylists()
  },
  methods: {
    /**
     * 获取音乐歌单列表
     * 2021-12-30 10:53:52
     * @author SnowRock
     */
    async getPlaylists() {
      try {
        const { data } = await fetchPlaylistList({
          ...this.listQuery
        })
        this.playlists = data.list.reduce((total, i) => {
          if (total.length <= 0 || total[total.length - 1].length >= 5) {
            total.push([])
          }
          total[total.length - 1].push(i)
          return total
        }, [])
        this.total = data.total
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  width: 1320px;
  margin: 0 auto;
  padding-top: 70px;
  min-height: calc(100vh - 268px);
  padding-bottom: 80px;
  .breadcrumb {
    width: 1200px;
    padding: 40px 0;
    /deep/ {
      .el-breadcrumb__inner {
        @include font-bold($size: 20px, $color: rgba(51, 51, 51, 0.6));
        line-height: 28px;
      }
      .el-breadcrumb__separator {
        @include font-bold(
          $size: 18px,
          $color: rgba(51, 51, 51, 0.6),
          $weight: bold
        );
      }
    }
  }

  .main {
    padding-top: 20px;
  }
}
</style>
