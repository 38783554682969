<template>
  <div class="playlist-item-wrapper">
    <SongList
      v-for="(subItem, subIndex) in data"
      :key="subIndex"
      :data="subItem"
      :playActive=true
      :row-index="subIndex"
      :hover-index="subIndex"
      :checked="subItem.playlist_id === selectPlaylistId"
      :isShowPlay="songList.hoverIndex == subIndex"
      @onMouseover="handleSelectPlaylist(subItem, subIndex)"
      @onMouseleave="songList.hoverIndex = -1"
      @onPlay="handlePlayAll(subItem, 2)"
      @onClick="handleClickGoDetail(subItem)"
    />
    <MusicList
      v-show="songList.hoverIndex > -1"
      :showOrder=false
      :data.sync="musicList"
    />
  </div>
</template>

<script>
import SongList from '@/components/SongList'
import MusicList from '@/components/MusicList'
import { playlistMusic } from '@/api/music'
import Buttons from '@/layout/mixins/Buttons'
// import { mapState } from 'vuex'

export default {
  name: 'PlaylistItem',
  components: { MusicList, SongList },
  props: {
    data: {
      type: null,
      required: true,
      default() {
        return []
      }
    },
    selectPlaylistId: {
      type: null,
      default: null
    }
  },
  data() {
    return {
      musicList: [],
      songList: {
        rowIndex: -1,
        hoverIndex: -1,
        currentId: 0, // 当前歌单id
      }
    }
  },
  mixins: [Buttons],
  // computed:{
  //   ...mapState({
  //     playAllstatus: (state) => state.music.isDisabledplayAllbtn
  //   })
  // },
  methods: {
    handleSongMouseover(ele, rowIndex){
      this.songList.hoverIndex = rowIndex;
    },
    /**
     * 获取音乐列表
     * 2021-12-30 10:19:39
     * @author SnowRock
     */
    async getMusicListByPlaylist() {
      try {
        const { data } = await playlistMusic({
          playlist_id: this.selectPlaylistId
        })
        this.musicList = data
      } catch (e) {
        console.log(e)
      }
    },

    /**
     * 处理选中音乐列表
     * 2021-12-30 10:48:13
     * @author SnowRock
     */
    handleSelectPlaylist(ele, rowIndex) {
      this.songList.hoverIndex = rowIndex;

      // console.log(ele)
      // if (
      //   this.songList.rowIndex === rowIndex &&
      //   this.songList.currentId === ele.object_id
      // ) {
      //   // 音乐收起
      //   this.songList.rowIndex = -1
      // }else{
        this.$emit('update:selectPlaylistId', this.selectPlaylistId === ele.playlist_id ? null : ele.playlist_id)
        this.$nextTick(() => {
          if (!this.selectPlaylistId) return
          this.getMusicListByPlaylist()
        })
      // }
    },
    // 点击列表跳转详情
    async handleClickGoDetail(ele) {
      this.$router.push(`/music/detail/${ele.object_id}`);
    },
    /**
     * 播放全部
     */
    async handlePlayAll(ele, type){
      // 音乐展开
      this.songList.currentId = ele.object_id
      this.songList.rowIndex = -1
      try {
        const { data } = await playlistMusic({
          playlist_id: this.songList.currentId
        })
        // console.log(data)
        this.$store.dispatch('music/updateOrderList', data);
        await this.handlePlayerAll(data[0], type); // 限制音乐/音效试听播放次数
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.playlist-item {
  &-wrapper {
    margin-bottom: 26px;
    /deep/ {
      .vf-song-list-container {
        width: calc((100% - 104px) / 5);
        display: inline-block;
        & + .vf-song-list-container {
          margin-left: 26px;
        }
      }
    }
  }
}
</style>
